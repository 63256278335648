import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import TosBody from "./TosBody";

function Tos() {
  return (
    <>
      <Breadcrumb pageName="Terms of Use" />
      <div className="contact-area sec-mar-t">
        <TosBody />
      </div>
    </>
  );
}

export default Tos;