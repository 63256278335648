/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactForm() {

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formElement = e.target;

    try {
      const response = await emailjs.sendForm(
        "service_lncli2c", // replace with your EmailJS service ID
        "template_t70ba2b", // replace with your EmailJS template ID
        formElement,
        "lqDYtqI1vDxBu9yNE" // replace with your EmailJS user ID
      );

      console.log(response);

      if (response.status === 200) {
        toast.success("Send successfully");

        formElement.reset();
      } else {
        toast.error("Submission failed. Please try again.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Submission failed. Please try again.");
    }
  };
  
  

  return (
    <>
    <style>
        {`
          .form-control2 {
            background: #FFFFFF;
            border: 1px solid #E9E9E9;
            box-sizing: border-box;
            border-radius: 30px;
            width: 100%;
            margin-bottom: 40px;
            height: 60px;
            padding: 0 25px;
            appearance: none;
          }
        `}
      </style>
      <div className="contact-information">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6">
              <div className="contact-form">
                <h3>Have Any Questions</h3>
                <form
                  onSubmit={handleSubmit}
                  method="post"
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <input
                        type="text"
                        name="first_name"
                        placeholder="First Name *"
                      required/>
                    </div>
                    <div className="col-xl-6">
                      <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name *"
                      required/>
                    </div>
                    <div className="col-xl-6">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address *" required
                      />
                    </div>
                    <div className="col-xl-6">
                      <input
                        type="phone"
                        name="phone"
                        placeholder="Phone Number *"
                      required/>
                    </div>
                    <div className="col-xl-6">
                        <select id="category" className="form-control2" name="Product" defaultValue="Choose A Category *" required>
                          <option value="">Choose A Category *</option>
                          <option value="Bulk SMS">Bulk SMS</option>
                          <option value="Domain, Hosting Or Server">Domain, Hosting Or Server</option>
                          <option value="Restaurant">Restaurant</option>
                          <option value="Retail">Retail</option>
                          <option value="Pharmacy">Pharmacy</option>
                          <option value="Supershop">Supershop</option>
                          <option value="Retail Store">Retail Store</option>
                          <option value="Sales & Distribution">Sales & Distribution</option>
                          <option value="Ecommerce">Ecommerce</option>
                          <option value="Web Development">web Development</option>
                          <option value="Financial Accounting">Financial Accounting</option>
                          <option value="HR Payroll">HR Payroll</option>
                          <option value="ERP">ERP</option>
                          <option value="Apps">Apps</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
               
                    <div className="col-xl-6">
                      <input type="text" name="subject" placeholder="Subject" />
                    </div>
                    <div className="col-12">
                      <textarea
                        name="message"
                        cols={30}
                        rows={10}
                        placeholder="How we can help you? *"
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-12">
                      <p>By sending this form I confirm that I have read and accept Softhab <a href="https://www.softhab.com/privacy-policy">Privacy Policy</a></p>
                    </div>
                     
                    <div className="col-12">
                      <input type="submit" value="Send Message" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="google-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.863622497183!2d90.39162092533597!3d23.75224217866918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b98b3c2cbde9%3A0x7cb683682562a25c!2sShah%20Ali%20Tower!5e0!3m2!1sen!2sbd!4v1704617309487!5m2!1sen!2sbd"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ContactForm;
