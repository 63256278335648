import React from "react";

function ContactUsArea() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col col-xl-6">
            <div className="title black">
              <span>Get In Touch</span>
              <h5>If you have any questions, please don’t hesitate to send us a message</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4">
            <div className="office-info">
              <div className="icon">
                <i className="bi bi-geo-alt" />
              </div>
              <h4>OUR OFFICE</h4>
              <p>
                33, Kawran Bazar, Shah Ali Tower (5th Floor), Tejgaon, Dhaka 1215, Bangladesh
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-4">
            <div className="office-info">
              <div className="icon">
                <i className="bi bi-telephone" />
              </div>
              <h4>Phone</h4>
              <a href="tel:+8801330996691">+880 1330 9966 91 (10AM - 7PM)</a>
              <a href="tel:+8809611860910">+880 9611 8609 10 (09AM - 11PM)</a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-4">
            <div className="office-info">
              <div className="icon">
                <i className="bi bi-envelope" />
              </div>
              <h4>Email</h4>
              <a href="mailto:enquiry@softhab.com">enquiry@softhab.com</a>
              <a href="mailto:support@softhab.com">support@softhab.com</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsArea;
