import React from "react";
function OurPartnerArea() {
  return (
    <>
      <section className="our-partner">
        <div className="container-fluid g-0 overflow-hidden">
          <div className="row align-items-center g-0">
            <div className="col-12 col-xl-6">
              <div className="newsletter">
                <div className="subscribes">
                  <span>Get In Touch</span>
                  <h1>Subscribe Our</h1>
                  <h2>Newsletter</h2>
                  <div className="subscribe-form">
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      action="#"
                      method="post"
                    >
                      <input
                        type="email"
                        name="email"
                        placeholder="Type Your Email"
                      />
                      <input type="submit" defaultValue="Connect" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <div className="our-clients">
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-4 col-xl-6">
                    <div className="title">
                      <span>Our Clients</span>
                      <h2>We are proud to works with.</h2>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-1-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.saejp.gov.bd" target="_blank" rel="noopener noreferrer">www.saejp.gov.bd</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-2-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.srsc.edu.bd" target="_blank" rel="noopener noreferrer">www.srsc.edu.bd</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-11-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.bu.edu.bd" target="_blank" rel="noopener noreferrer">www.bu.edu.bd</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-3-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.shop.alharamainperfumes.com/bangladesh/" target="_blank" rel="noopener noreferrer">www.shop.alharamainperfumes.com/bangladesh/</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-4-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.hamko.com.bd" target="_blank" rel="noopener noreferrer">www.hamko.com.bd</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-5-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.timeholidays.com.bd" target="_blank" rel="noopener noreferrer">www.timeholidays.com.bd</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-6-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.curelifehealthcare.com" target="_blank" rel="noopener noreferrer">www.curelifehealthcare.com</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-7-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.dailypostbd.com" target="_blank" rel="noopener noreferrer">www.dailypostbd.com</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-8-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.uiitech.com" target="_blank" rel="noopener noreferrer">www.uiitech.com</a></span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-9-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.ximim.com" target="_blank" rel="noopener noreferrer">www.ximim.com</a></span>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div className="single-client">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/clients/client-10-softhab.png"
                        }
                        alt="images"
                      />
                      <div className="client-hover">
                        <span><a href="https://www.hayaativenture.com" target="_blank" rel="noopener noreferrer">www.hayaativenture.com</a></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurPartnerArea;
