import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import RefundPageBody from "./RefundPageBody";

function RefundPage() {
  return (
    <>
      <Breadcrumb pageName="Refund & Cancellation Policy" />
      <div className="contact-area sec-mar-t">
        <RefundPageBody />
      </div>
    </>
  );
}

export default RefundPage;
