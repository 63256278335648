import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const useCurrentYear = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const updateYear = () => {
      setCurrentYear(new Date().getFullYear());
    };

    const intervalId = setInterval(() => {
      const currentDate = new Date();
      if (currentDate.getMonth() === 0 && currentDate.getDate() === 1) {
        updateYear();
      }
    }, 60000); 
    
    return () => clearInterval(intervalId);
  }, []);

  return currentYear;
};

function Footer() {
  const currentYear = useCurrentYear();
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3">
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link onClick={scrollTop} to={"#"}>
                    <img
                      src={process.env.PUBLIC_URL + "/images/logo.svg"}
                      alt="img"
                      width="80%"
                    />
                  </Link>
                </div>
                <address>
                  <h4>Smart Solutions, Seamless Software</h4>
                  <p>We pioneer digital excellence, crafting innovative software solutions that empower businesses to thrive in the ever-evolving tech landscape. Elevate your possibilities with Softhab's cutting-edge technologies and unparalleled expertise.</p>
                </address>
                <ul className="social-media-icons">
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/softhab"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.twitter.com/"
                      target="_blank"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>

                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/company/softhab"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3">
              <div className="footer-widget">
                <h4>Our Services</h4>
                <ul className="footer-menu">
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`#`}
                    >
                      Software Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`#`}
                    >
                      Web Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`#`}
                    >
                      App Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`#`}
                    >
                      Graphic Design
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`#`}
                    >
                      Search Engine Optimization
                    </Link>
                  </li>
                  <li>
                      <a
                        href="https://hosthab.com"
                        onClick={scrollTop}
                        target="_blank" rel="noopener noreferrer"
                      >
                        Domain Registration
                      </a>
                  </li>
                  <li>
                      <a
                        href="https://hosthab.com"
                        onClick={scrollTop}
                        target="_blank" rel="noopener noreferrer"
                      >
                        Web Hosting
                      </a>
                  </li>
                  <li>
                      <Link
                        onClick={scrollTop}
                        to={`#`}
                      >
                        Bulk SMS
                      </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3">
              <div className="footer-widget">
                <h4>Company</h4>
                <ul className="footer-menu">
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/about`}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`#`}
                    >
                      Our Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`#`}
                    >
                      Our Softwares
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`#`}
                    >
                      Case Study
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/blog`}
                    >
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/contact`}
                    >
                      Careers
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3">
              <div className="footer-widget">
                <h4>CONTACT US</h4>
                <div className="number">
                  <div className="num-icon">
                    <i className="fas fa-phone-alt" />
                  </div>
                  <div className="phone">
                      <a rel="noopener noreferrer" href="tel:+8801330996691">
                      +880 1330 9966 91 (10AM - 7PM)
                    </a>
                    <a rel="noopener noreferrer" href="tel:+8809611860910">
                      +880 9611 8609 10 (09AM - 11PM)
                    </a>
                  </div>
                </div> 
                <div className="office-mail">
                  <div className="mail-icon">
                    <i className="far fa-envelope" />
                  </div>
                  <div className="email">
                    <a rel="noopener noreferrer" href="mailto:enquiry@softhab.com">
                      enquiry@softhab.com
                    </a>
                    <a rel="noopener noreferrer" href="mailto:support@softhab.com">
                      support@softhab.com
                    </a>
                  </div>
                </div>
                <div className="address">
                  <div className="address-icon">
                    <i className="fas fa-map-marker-alt" />
                  </div>
                  <p>33, Kawran Bazar, Shah Ali Tower (5th Floor), Tejgaon, Dhaka 1215, Bangladesh</p>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-logo">
            <div className="col-12">
              <img src={process.env.PUBLIC_URL + "/images/payment-method/softhab-payment-method-v1.png"} alt="Payment Methods Supported by Softhab" />
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <div><span>Trade License No. TRAD/DNCC/031982/2023.</span></div>
                  <span>
                    Copyright &copy; {currentYear} <b>Softhab</b> All Rights Reserved .
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  
                  <li>
                    <Link onClick={scrollTop} to={"tos"}>
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={"privacy-policy"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={"refund-cancellation-policy"}>
                      Refund &amp; Cancellation Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="scroll-top opacity">
        <ScrollButton></ScrollButton>
      </div>
    </>
  );
}

export default Footer;

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <i
      className="bi bi-arrow-up"
      onClick={scrollToTop}
      style={{ display: visible ? "inline" : "none" }}
    >
      <span>top</span>
    </i>
  );
};
