import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import PrivacyPolicyBody from "./PrivacyPolicyBody";

function PrivacyPolicy() {
  return (
    <>
      <Breadcrumb pageName="Privacy Policy" />
      <div className="contact-area sec-mar-t">
        <PrivacyPolicyBody />
      </div>
    </>
  );
}

export default PrivacyPolicy;
